
const vidListDb = [
  {
    embed: "https://www.youtube.com/embed/ACtF5km91WI",
    img: 'que_necesito.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/QkqtMcz2CB4",
    img: 'fretless.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/cj4xbi6gsQc",
    img: 'topbajos_500px.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/KW6ii7_cni4",
    img: 'voz_500px.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/HybS8EI-ils",
    img: 'slap2.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/hX1ZQqNoo0M",
    img: 'larry.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/Y_3qEEuTky4",
    img: 'top_funk.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/yfZxVAukGi0",
    img: 'pua_bajo.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/uhJ9mpFPRVs",
    img: 'batallapvsj.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/T91QtCY4bBc",
    img: 'jacospower.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/wGEiqmv75Tw",
    img: 'tinchoresponde2_500px.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/AtocfXcJMUI",
    img: 'castlevania.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/VgtZ5TOgF8o",
    img: '5lineasfacilesrock.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/4VDFA26RjGQ",
    img: 'divididos2.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/M8iJQkzt8Pg",
    img: 'divididos1.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/efEYBr6Le4Y",
    img: 'ser_bajista.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/JL18xyKNxhs",
    img: 'escala_blues.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/dd9tqDbfBx4",
    img: 'double_stops.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/IO8jRsb0sXU",
    img: 'tecnica5.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/gOggeI-mGAg",
    img: 'tecnica4.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/PR-_8BCDetQ",
    img: 'tecnica3.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/TvKis-FiZ-M",
    img: 'tecnica2.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/Cs9CJZiULAk",
    img: 'tecnica1.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/_NzOSZvbJ78",
    img: 'crecimiento.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/bbtP2DmBhA0",
    img: 'funkea.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/lsbyfD_8NBY",
    img: 'tinchores.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/cFOUNgDDe8k",
    img: 'shuffle.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/nw2Pb5ou_uo",
    img: 'vitti2.jpg',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/fNLBLNI_Fqs",
    img: 'aznar.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/KSV0PiffTVc",
    img: 'palm.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/1xgmM1ApdCg",
    img: 'jamiroquai.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/5lWxCeI5hyo",
    img: 'jedi.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/gNlOE5rsDCQ",
    img: 'slapea.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/ZvqReNl2vmA",
    img: 'jaco.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/uCumoYPDxJM",
    img: '5tips.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/xBGnLVlVH1E",
    img: 'dragon.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/6h7OTqcs1T4",
    img: 'flea.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/mfOL2PFToCs",
    img: 'arnedo.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/YtNyeNKT1t4",
    img: 'murguita.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/yAlpHFrrclo",
    img: 'cheques.jpg',
    categoria: 'leccion',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/w6XMN_7XeqM",
    img: 'jacojam.jpg', //jaco jam
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/hbqYqTiig68",
    img: 'thumb.jpg', //the thumb
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/w0AARpGsDhE",
    img: 'kotzen.jpg', // till you put me down
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/dcJrfRzGtUs",
    img: 'mcbride.jpg', // mcbride
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/QM2edqrhLOw",
    img: 'almabudin.jpg', // alma de budin
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/ykD1CMaf59w",
    img: 'navidad.jpg', // funky jam
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/w52KXaRGX60",
    img: 'hip.jpg', // what is hip
    tema: 'What Is Hip',
    artista: 'Tower Of Power',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/yYAmW-sF2t0",
    img: 'swing.jpg', // it dont mean a thing
    tema: 'It Dont Mean a Thing',
    artista: 'Oscar Pettiford',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/jX7alU9PA-A",
    img: 'bajan.jpg', // bajan
    tema: 'Bajan',
    artista: 'Pescado Rabioso',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/_Yl8sq2SZh4",
    img: 'doors.jpg', // people are strange
    tema: 'People Are Strange',
    artista: 'The Doors',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/k0SAxLrG03s",
    img: 'sly.jpg', // sly
    tema: 'Sly',
    artista: 'Herbie Hancock',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/YXqClQzEkZM",
    img: 'jamerson.jpg', // james jamerson
    tema: 'James Jamerson',
    artista: 'Motown',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/GuJUY4Zmvz8",
    img: 'cinema.jpg', // cinema paradiso
    tema: 'Cinema Paradiso',
    artista: 'Chord Melody',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/tXVtwlKh-d0",
    img: 'mike.jpg', // Mikes groove
    tema: "Mike's Groove",
    artista: 'Michael League',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/18MWL2R3WhE",
    img: 'chequescover.jpg', // Cheques
    tema: 'Cheques',
    artista: 'L.A. Spinetta',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/-bAdH4J9Vvs",
    img: 'allemande.jpg', // allemande
    tema: 'Allemande',
    artista: 'J.S. Bach',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/oxvOYOVHUwc",
    img: 'cometogether.jpg', // come together
    tema: 'Come Together',
    artista: 'Carol Kaye',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/togiw9jH2rg",
    img: 'justfriends.jpg', // just friends
    tema: 'Just Friends',
    artista: 'Chet Baker',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/M4Rq9H6EDys",
    img: 'somebody.jpg', // somebody to love
    tema: 'Somebody To Love',
    artista: 'Queen',
    categoria: 'cover',
    tipo: 'youtube',
  },
  {
    embed: "https://www.youtube.com/embed/TKi36LprRXw",
    img: 'mariposacover.jpg', // mariposa
    tema: 'Mariposa Pontiac',
    artista: 'Redonditos de Ricota',
    categoria: 'cover',
    tipo: 'youtube',
  }
]


export default vidListDb